import axios from "@/plugins/axios.js"

const actions = {
  fetchOrders({ commit, state }, force = false) {
    if(!force && state.orders.length) return
    return new Promise((resolve, reject) => {
      axios.get("/orders")
        .then((response) => {
          if(response.data.success){
            commit('SET_ORDERS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchOrder({ commit }, orderId) {
    return new Promise((resolve, reject) => {
      axios.get(`/orders/${orderId}/show`)
        .then((response) => {
          if(response.data.success){
            commit('SET_ORDER', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchMessages({ commit }, orderId) {
    return new Promise((resolve, reject) => {
      axios.get(`/order-messages/${orderId}/show`)
        .then((response) => {
          if(response.data.success){
            commit('SET_ORDER_MESSAGES', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  multipleAction({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(payload.action, payload.formData)
        .then((response) => {
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}

const getters = {
  getMessages: (state, getters, rootState) => {
    let messages = state.order.messages.filter((msg) => msg.message.toLowerCase().includes(state.messageSearchQuery.toLowerCase()))
    return messages;
  },
}




const mutations = {
  SET_ORDERS(state, orders) {
    state.orders = orders
  },
  SET_ORDER(state, order){
  	state.order = order
    if(state.orders.length){
      const dataIndex = state.orders.findIndex((o) => o.id == order.id);
      Object.assign(state.orders[dataIndex], order);
    }
  },
  DELETE_ORDER(state, orderId){
      const itemIndex = state.orders.findIndex((item) => item.id == orderId)
      state.orders.splice(itemIndex, 1)
  },

    /**************** THIS IS FOR MESSAGES********************/
  SET_ORDER_MESSAGES(state, messages){
    state.order.messages = [...messages]
  },
  ADD_MESSAGE(state, message){
    state.order.messages.push(message)
  },
  UPDATE_MESSAGE(state, message){
    const dataIndex = state.order.messages.findIndex((a) => a.id == message.id);
    Object.assign(state.order.messages[dataIndex], message);
  },
  DELETE_MESSAGE(state, messageId){
    const itemIndex = state.order.messages.findIndex((item) => item.id == messageId)
      state.order.messages.splice(itemIndex, 1)
  },
}

const state = {
  orders: [],
  order: {},
}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

