import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'

// axios
import axios from "./plugins/axios.js"
Vue.prototype.$http = axios

// Theme Configurations
import '@/themeConfig.js'

// Install BootstrapVue & Optionally BootstrapVue icon components plugin
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import './plugins/toastification.js'
import './plugins/simplebar.js'
import './plugins/sweetalert2.js'
import './plugins/vee-validate.js'
import './plugins/vue-slidebar.js'
import './plugins/vue-lightbox.js'
import './plugins/vue-click-outside.js'
import './plugins/quill-editor.js'
import './plugins/chartist.js'
import './plugins/string-filter'

// Vue Router
import router from './router'

// Vuex Store
import store from './store/store'

// Filters
import './filters/filters'

// Mixins
import mixins from './mixins/mixins'
Vue.mixin(mixins);

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
