import axios from "@/plugins/axios.js"

const actions = {
  fetchReviews({ commit, state}, force = false) {
    if(!force && state.reviews.length) return
    return new Promise((resolve, reject) => {
      axios.get("/reviews")
        .then((response) => {
          if(response.data.success){
              commit('SET_REVIEWS', response.data.data)
            }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchReview({ commit }, reviewId) {
    return new Promise((resolve, reject) => {
      axios.get(`/reviews/${reviewId}/show`)
        .then((response) => {
          if(response.data.success){
              commit('SET_REVIEW', response.data.data)
            }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}

const getters = {
  reviews: (state, getters) => (sort) => {
      let reviews = state.reviews.filter((review) => review.content.toLowerCase().includes(state.reviewSearchQuery.toLowerCase()))
      if(sort === 'real') {
          reviews = [...reviews.filter((review) => review.is_real === 1)]
      }
      if(sort === 'created') {
          reviews = [...reviews.filter((review) => review.is_real === 0)]
      }
      return reviews
  },

}


const mutations = {
  SET_REVIEW_SEARCH_QUERY(state, query) {
    state.reviewSearchQuery = query
 },
 ADD_REVIEW(state, review) {
   state.reviews.unshift(review)
 },
 SET_REVIEWS(state, reviews) {
   state.reviews = reviews
 },
 UPDATE_REVIEW(state, review){
   state.review = review
   if(state.reviews.length){
       const dataIndex = state.reviews.findIndex((g) => g.id == review.id);
       Object.assign(state.reviews[dataIndex], review);
     }
 },
 DELETE_REVIEW(state, reviewId){
   const itemIndex = state.reviews.findIndex((item) => item.id == reviewId)
   state.reviews.splice(itemIndex, 1)
 },
}

const state = {
  reviewSearchQuery: "",
  postSearchQuery: "",
  reviews: [],
  review: {},
}

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

