import axios from "@/plugins/axios.js"

const actions = {
  addCategory({ commit }, category) {
    return new Promise((resolve, reject) => {
      axios.post("/categories/create", category)
      .then((response) => {
          if(response.data.success){
            commit('ADD_CATEGORY', response.data.data)
          }
          resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  fetchCategories({ commit, state}, force = false) {
    if(!force && state.categories.length) return
    return new Promise((resolve, reject) => {
      axios.get("/categories")
        .then((response) => {
          if(response.data.success){
              commit('SET_CATEGORIES', response.data.data)
            }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchCategory({ commit }, categoryId) {
    return new Promise((resolve, reject) => {
      axios.get(`/categories/${categoryId}/show`)
        .then((response) => {
          if(response.data.success){
              commit('SET_CATEGORY', response.data.data)
            }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}

const getters = {
  // getItem: state => (productId) => state.products.find((product) => product.id == productId),
}


const mutations = {
  ADD_CATEGORY(state, category) {
      state.categories.unshift(category)
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories
  },
  SET_CATEGORY(state, category){
    state.category = category
  },
  UPDATE_CATEGORY(state, category){
    Object.assign(state.category, category)
    if(state.categories.length){
      const dataIndex = state.categories.findIndex((o) => o.id == category.id);
      Object.assign(state.categories[dataIndex], category);
    }
  },
  DELETE_CATEGORY(state, categoryId) {
    const ItemIndex = state.categories.findIndex((item) => item.id == categoryId)
    state.categories.splice(ItemIndex, 1)
  },

  /************** FOR DESCRIPTION *****************/
  UPDATE_DESCRIPTION(state, description){
    state.category.description = description
  },

  /************** FOR PORTFOLIOS *****************/
  ADD_PORTFOLIO(state, portfolio){
    state.category.portfolios.unshift(portfolio);
  },
  SET_PORTFOLIOS(state, portfolios){
    state.category.portfolios = [...portfolios];
  },
  UPDATE_PORTFOLIO(state, portfolio){
    const dataIndex = state.category.portfolios.findIndex((f) => f.id == portfolio.id)
      Object.assign(state.category.portfolios[dataIndex], portfolio)
  },
  DELETE_PORTFOLIO(state, portfolioId){
    const ItemIndex = state.category.portfolios.findIndex((item) => item.id == portfolioId)
    state.category.portfolios.splice(ItemIndex, 1);
  }
}

const state = {
  categories: [],
  category: {},
}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

