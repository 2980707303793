export default [
  {
  // =============================================================================
  // MAIN LAYOUT ROUTES
  // =============================================================================
      path: '',
      redirect: '/',
      component: () => import('@/layouts/Main.vue'),
      children: [
          {
              path: '/',
              name: 'dashboard',
              component: () => import('@views/Dashboard.vue'),
              meta: {
                breadcrumb: [

                  {
                    text: "Dashboard",
                    active: true
                  }
                ],
                title: 'Portal',
                authRequired: true,
              }
          },
          {
              path: '/account',
              name: 'account',
              component: () => import('@views/account/UserEdit.vue'),
              meta: {
                 breadcrumb: [
                    {
                      text: "Dashboard",
                      to:{ path:"/"}
                    },
                    {
                      text: "Account",
                      active: true
                    }
                ],
                title: 'Profile',
                authRequired: true,
              },
          },
          {
              path: '/orders',
              name: 'order',
              component: () => import('@views/orders/OrderList.vue'),
              meta: {
                breadcrumb: [
                    {
                      text: "Dashboard",
                      to:{ path:"/"}
                    },
                    {
                      text: "Orders",
                      active: true
                    }
                ],
                title: "Orders list",
                authRequired: true,
              },
          },
          {
              path: '/orders/:orderId(\\d+)',
              name: 'order-view',
              component: () => import('@views/orders/OrderShow.vue'),
              meta: {
                  breadcrumb: [
                    {
                      text: "Orders",
                      to:{ path:"/orders"}
                    },
                    {
                      text: "Show",
                      active: true
                    }
                ],
                title: "View order",
                authRequired: true,
              },
          },
          {
              path: '/categories',
              name: 'categories',
              component: () => import('@views/categories/CategoryList.vue'),
              meta: {
              breadcrumb: [
                  {
                      text: "Dashboard",
                      to: { path:"/"}
                  },
                  {
                      text: "Categories",
                      active: true
                  }
              ],
              title: "Categories list",
              authRequired: true,
              },
          },
          {
              path: '/categories/create',
              name: 'category-create',
              component: () => import('@views/categories/CreateCategory.vue'),
              meta: {
              breadcrumb: [
                  {
                      text: "Categories",
                      to: { path:"/categories"}
                  },
                  {
                      text: "Create new",
                      active: true
                  }
              ],
              title: "Create category",
              authRequired: true,
              },
          },
          {
              path: '/categories/:categoryId(\\d+)',
              name: 'category-view',
              component: () => import('@views/categories/CategoryShow.vue'),
              meta: {
              breadcrumb: [
                  {
                      text: "Categories",
                      to: { path:"/categories"}
                  },
                  {
                      text: "Show",
                      active: true
                  }
              ],
              title: "View category",
              authRequired: true,
              },
          },
          {
              path: '/products',
              name: 'products',
              component: () => import('@views/products/ProductList.vue'),
              meta: {
              breadcrumb: [
                  {
                  text: "Dashboard",
                  to: { path:"/"}
                  },
                  {
                  text: "Products",
                  active: true
                  }
              ],
              title: "Products list",
              authRequired: true,
              },
          },
          {
              path: '/products/create',
              name: 'product-create',
              component: () => import('@views/products/CreateProduct.vue'),
              meta: {
              breadcrumb: [
                  {
                      text: "Products",
                      to: { path:"/products"}
                  },
                  {
                      text: "Create new",
                      active: true
                  }
              ],
              title: "Create product",
              authRequired: true,
              },
          },
          {
              path: '/products/:productId(\\d+)',
              name: 'product-view',
              component: () => import('@views/products/ProductShow.vue'),
              meta: {
              breadcrumb: [
                  {
                      text: "Products",
                      to: { path:"/products"}
                  },
                  {
                      text: "Show",
                      active: true
                  }
              ],
              title: "View product",
              authRequired: true,
              },
          },
          {
              path: '/users',
              name: 'users-list',
              component: () => import('@views/users/UserList.vue'),
              meta: {
                breadcrumb: [
                    {
                      text: "Dashboard",
                      to: { path:"/"}
                    },
                    {
                      text: "User list",
                      active: true
                    }
                ],
                title: "Users list",
                authRequired: true,
              },
          },
          {
              path: '/users/create',
              name: 'users-create',
              component: () => import('@views/users/UserCreate.vue'),
              meta: {
                breadcrumb: [
                    {
                      text: "Dashboard",
                      to: { path:"/"}
                    },
                    {
                      text: "User",
                      active: true
                    }
                ],
                title: "Create user",
                authRequired: true,
              },
          },
          {
              path: '/users/:userId(\\d+)',
              name: 'user-view',
              component: () => import('@views/users/edit/UserEdit.vue'),
              meta: {
                breadcrumb: [
                    {
                      text: "Dashboard",
                      to: { path:"/"}
                    },
                    {
                      text: "User",
                      active: true
                    }
                ],
                title: "Vew user",
                authRequired: true,

              },
          },
          {
              path: '/reviews',
              name: 'reviews',
              component: () => import('@views/reviews/ReviewList.vue'),
              meta: {
                  breadcrumb: [
                  {
                    text: "Dashboard",
                    to: { path:"/"}
                  },
                  {
                    text: "Review list",
                    active: true
                  }
                ],
                title: 'Reviews',
                authRequired: true,
              }
          },
          {
              path: '/payments',
              name: 'payments',
              component: () => import('@views/payments/Payments.vue'),
              meta: {
                  breadcrumb: [
                    {
                      text: "Dashboard",
                      to: { path:"/"}
                    },
                    {
                      text: "Payments list",
                      active: true
                    }
                ],
                title: "Payments",
                authRequired: true,
              }
          },
          {
              path: '/notifications',
              name: 'notifications',
              component: () => import('@views/notification/Notifications.vue'),
              meta: {
                  breadcrumb: [
                    {
                      text: "Dashboard",
                      to:{ path:"/"}
                    },
                    {
                      text: "Notifications list",
                      active: true
                    }
                ],
                title: "Notifications",
                authRequired: true,
              }
          },
          {
            path: '/settings/coupons',
            name: 'settings/coupons',
            component: () => import('@views/settings/Coupons.vue'),
            meta: {
                breadcrumb: [
                  {
                    text: "Dashboard",
                    to:{ path:"/"}
                  },
                  {
                    text: "Coupons list",
                    active: true
                  }
              ],
              title: "Coupons",
              authRequired: true,
            }
        },
      ]
  },
  // =============================================================================
  // FULL PAGE LAYOUTS
  // =============================================================================
  {
      path: '',
      component: () => import('@/layouts/FullPage.vue'),
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/pages/Login.vue'),
          meta: {
            guestRequired: true,
          }
        },
        {
          path: '/error-404',
          name: 'error-404',
          component: () => import('@/views/pages/Error404.vue'),
        },
        {
          path: '/error-500',
          name: 'error-500',
          component: () => import('@/views/pages/Error500.vue'),
        },
      ]
  },
  // Redirect to 404 page, if no match found
  {
      path: '*',
      redirect: '/error-404'
  },
]